
.subscribe-container{
  float: right;
 }
 .faithMinus-help{
    
 }
 .rentDaap-help ul li a{
     color: #16151a !important;
     font-size: 15px;
 }
 li {
  list-style: none ;
 }
 .rentDaap-help ul li a:hover{
     color: #002e91 !important;
     cursor: pointer;
     list-style: none !important;
 }
 .footer-link{
     color:  #16151a !important;
     font-size: 18px;
     font-weight: 500;
 }
 .footer-link:hover{
     color: #002e91!important;
     cursor: pointer;
 }
 .Footer-container{
  background-color: #f1f1f1;
 }
 .rentDaap-Img{
     width: 30%;
     height: 150px;
 }
 
 .subscribe-rent-btn{
     font-weight: 500;
     width: 120px;
     text-decoration: none;
     height: 45px;
     border: none;
     text-align: center;
     background-color: #002e91;
     color: #fff;
 }
 .subscribe-field{
     padding-left: 10px;
     width: 235px;
     height: 45px;
 }

 .subscribe-detail{
     color:  #16151a;;
     font-size: 18px;
     font-weight: 500;
     margin-bottom: 13px;
 }
 .other-footer-txt{
     color:  #16151a;;
 }
 .social-links{
  display: flex;
  margin-left: -12px;
 }

 .flex-box-subs{
     display: flex;
 }

 .social-img{
    width: 30px;
    height: 30px;
    margin: 7px;
    margin-top: 20px;
}

.social-sm-flex{
    display: flex;
}