/*----------------
margin space
-----------------*/

.mb0 { margin-bottom: 0px; }
.mb5 { margin-bottom: 5px; }
.mb10 { margin-bottom: 10px; }
.mb20 { margin-bottom: 20px; }
.mb30 { margin-bottom: 30px; }
.mb40 { margin-bottom: 40px; }
.mb60 { margin-bottom: 60px; }
.mb80 { margin-bottom: 80px; }
.mb100 { margin-bottom: 100px; }
.mt0 { margin-top: 0px; }
.mt10 { margin-top: 10px; }
.mt20 { margin-top: 20px; }
.mt30 { margin-top: 30px; }
.mt40 { margin-top: 40px; }
.mt60 { margin-top: 60px; }
.mt80 { margin-top: 80px; }
.mt100 { margin-top: 100px; }
.mr0 { margin-right: 0px; }
.mr10 { margin-right: 10px; }
.mr20 { margin-right: 10px; }
.ml0 { margin-left: 0px; }
.ml10 { margin-left: 10px; }
.ml20 { margin-left: 20px; }

/*----------------
less margin space from top
-----------------*/
.mt-20 { margin-top: -20px; }
.mt-40 { margin-top: -40px; }
.mt-60 { margin-top: -60px; }
.mt-80 { margin-top: -80px; }

/*---------------
Padding space
-----------------*/
.nopadding { padding: 0px; }
.nopr { padding-right: 0px; }
.nopl { padding-left: 0px; }
.pinside10 { padding: 10px; }
.pinside20 { padding: 20px; }
.pinside30 { padding: 30px; }
.pinside40 { padding: 40px; }
.pinside50 { padding: 50px; }
.pinside60 { padding: 60px; }
.pinside80 { padding: 80px; }
.pinside100 { padding: 100px; }
.pdt10 { padding-top: 10px; }
.pdt20 { padding-top: 20px; }
.pdt30 { padding-top: 30px; }
.pdt40 { padding-top: 40px; }
.pdt60 { padding-top: 60px; }
.pdt80 { padding-top: 80px; }
.pdb10 { padding-bottom: 10px; }
.pdb20 { padding-bottom: 20px; }
.pdb30 { padding-bottom: 30px; }
.pdb40 { padding-bottom: 40px; }
.pdb60 { padding-bottom: 60px; }
.pdb80 { padding-bottom: 80px; }
.pdl10 { padding-left: 10px; }
.pdl20 { padding-left: 20px; }
.pdl30 { padding-left: 30px; }
.pdl40 { padding-left: 40px; }
.pdl60 { padding-left: 60px; }
.pdl70 { padding-left: 70px; }
.pdl80 { padding-left: 80px; }
.pdl120 { padding-left: 120px; }
.pdr10 { padding-right: 10px; }
.pdr20 { padding-right: 20px; }
.pdr30 { padding-right: 30px; }
.pdr40 { padding-right: 40px; }
.pdr60 { padding-right: 60px; }
.pdr80 { padding-right: 80px; }
* {
  padding: 0;
  margin: 0;
}
/*===================
 
=====================*/

/* 


.App {
  text-align: center;
  background-color: #ffffff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.text-thm-col{
  color: 
}

.banner-part{
  padding-top: 130px;
  padding-left: 60px;
  padding-bottom: 50px;
}
.banner {
  background: url(/images/banner-right-bg.png);
  background-repeat: no-repeat;
  /* width: 100%; */
  /* height: 100vh; */
  /* background-position: 50%; */
  /* padding-top: 170px; */
  background-size: cover;
}
.curator-part{
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right:60px;
}
.curatorbanner {
  background: url(/images/bannerlefty-bg.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.faithminus-section2 p{
 text-align: center;
 min-height: 150px;
}


#logo-carsoul .owl-nav{
  display:none;
  }
  #logo-carsoul .item{
  box-shadow: 16px 0 15px rgba(0, 0, 0, 0.1);
  background: #F5F9FB;
  padding: 10px;
  padding: 45px 0;
  border: 10px solid #ffffff;
  position: relative;
  z-index: 1;
  text-align: center !important;
  padding: 0px;
  margin-bottom: 10px;
  }
  
  #logo-carsoul .item img{
  vertical-align: middle;
  display: block;
  max-width: 100%;
  }

  .footers a {color:#000;}
.footers p {color:#000;}
.footers ul {line-height:30px;}
footer  .border {
  border: 1px solid #e6bcbc!important;
}
footer h5{
  color: #fe4788;
}
.social-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.social-menu li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 6px;
}
.social-menu li a i {
  width: 31px;
  height: 30px;
  background-color: #fff0;
  text-align: center;
  line-height: 30px;
  border-radius: 3px;
  color: #889cfa;
  font-size: 14px;
  box-shadow: 0 0 0 0 transparent;
  transition: 0.3s;
  border: 1px solid #464646;
}

.social-menu li a:hover i {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}
.progress-outer {
    background: #fff;
    border-radius: 0;
    padding: 13px;
    margin: 11px 0;
    box-shadow: 0 0 7px rgb(209, 219, 231);
}
.progress{
    height: 27px;
    margin: 0;
    overflow: visible;
    border-radius: 50px;
    background: #eaedf3;
    box-shadow: inset 0 10px  10px rgba(244, 245, 250,0.9);
}
.progress .progress-bar{
    border-radius: 50px;
}
.progress .progress-value{
    position: relative;
    left: -65px;
    top: 12px;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 2px;
}
.progress-bar.active{
    animation: reverse progress-bar-stripes 0.40s linear infinite, animate-positive 2s;
}
@-webkit-keyframes animate-positive{
    0% { width: 0%; }
}
@keyframes animate-positive {
    0% { width: 0%; }
}
.progress-bar{
  width:80%; 
  box-shadow:-1px 10px 10px rgba(91, 192, 222, 0.7);
}
.project-tab nav{
/*    padding: 10%;
    margin-top: -8%;*/
    background: #e3e3e3;
}
.project-tab #tabs{
    background: #007b5e;
    color: #eee;
}
.project-tab #tabs h6.section-title{
    color: #eee;
}
.project-tab #tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #0062cc;
    background-color: transparent;
    border-color: transparent transparent #f3f3f3;
    border-bottom: 3px solid !important;
    font-size: 16px;
    font-weight: bold;
}
.project-tab .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #171717;
    font-size: 16px;
    font-weight: 600;
}
.project-tab .nav-link:hover {
    border: none;
}
.project-tab thead{
    background: #f3f3f3;
    color: #333;
}
.project-tab a{
    text-decoration: none;
    color: #333;
    font-weight: 600;
}
.project-tab .tab-content>.tab-pane { 
    padding: 20px;
    background: #f5f5f5;
}
.card-without-border {
    padding: 15px 2px;
}
.card-without-border .list-group-flush>.list-group-item {
  border: none !important;
  padding: 5px 1.25rem;
  background: none;
}
.card-without-border h4{ 
    color: #5271ff;
}
.single-page{ 
    padding: 20px;
    background: #f5f5f5;
}
.single-page .btnplg{
  font-weight: 600;
  font-size: 18px;
}
.single-page h4{ 
    color: #5271ff;
}
.text-themecolor{
  color: #5271ff;
  font-weight: 600
}
.border-style-img {
    border-style: dashed;
    width: 200px;
    height: 200px;
    color: #a7a7a7;
    margin-right: 10px;
    margin-bottom: 10px;
        border: 1px solid #d4d4d4;
}
.border-style-img {
    display: inline-flex;
    justify-content: center;
}
.faithminus-plus-Img {
    height: 100%;
}

.com-modal .modal-header{
    border-bottom: none !important;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: inherit;
    background: #5271ff !important;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    border-radius: 50%;
    right: 5px;
    z-index: 10;
}
.close span{
    position: absolute;
    top: 1px;
    color: #fff;
    right: 6px;
}
.curator-box img{
    width: 90px;
    height: 90px;
    margin: 0 auto;
    border-radius: 50%;
    border: 1px solid #4646463b;
    margin-bottom: 15px;
}
.curator-box .btn{
    margin: 0 auto;

}

.star-rating {
    display: flex;
    flex-direction: row-reverse;
    font-size: 1.5em;
    justify-content: space-around;
    padding: 0 .2em;
    text-align: center;
    width: 5em;
    margin: 0 auto;
}

.star-rating input {
  display:none;
}

.star-rating label {
  color:#ccc;
  cursor:pointer;
}

.star-rating :checked ~ label {
  color:#f90;
}

.star-rating label:hover,
.star-rating label:hover ~ label {
  color:#fc0;
}