
.navbar-light .navbar-nav .nav-link {
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: 500;
}

.wallet-img {
  width: 25px;
  height: 25px;
  margin-right: 8px;
}

.asset-logo {
  width: 96px;
  height: 66px;
}

.btn-border.nav-link {
  border-bottom: 2px solid #000;
}

.right-border.nav-link {
  border-right: 2px solid #000;
  margin-right: 22px;
}

.alrt-flex{
  display: flex;
}

.alrt-user{
  width: 25px;
  height: 25px;
}

.dropdown-item.view-style{
 color: #ffffff;
 font-size:18px;
 font-weight: bold;
 text-align: center;
}

.d-inline-block.align-top.brand{
 height: 80px;
}

.nav-link.left-nav{
    font-size: 15px !important;
    font-weight: bold !important;
    text-transform: uppercase;
}

.navbar-text{
  font-size: 20px !important;
  font-weight: bold !important;
  color: #000 !important;
}
.bg-light{
  background: none !important;
}
.bg-light.scrolled {
  background-color: #f5f5f5!important;
  transition:500ms ease;
  box-shadow: 0px 0px 15px #0e0e0ee8;
}
.survey-nav .fixed-top{
  background:#000 !important ;
}