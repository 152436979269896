.dash-bgd{
    background-color:#a8b8ff ;
}

.bl-db-txt{
    color: #559ee2;
    font-size: 18px;
}

.db-bold{
    color: #559ee2;
    font-size: 18px;
    font-weight: bold;
}

.db-user-img{
    width: 40px;
    height: 40px;
    border-radius: 40px;
}

.fm-name-ctr{
    color: #559ee2;
    font-size: 20px;
    text-align: center;
}


.db-profile-cntr{
    display: flex;
    justify-content: center;
}

.white-btn-fm{
    background-color: #ffffff;
    border-color :#5271ff;
    width: 100px;
    height: 40px;
    border-radius: 20px;
}

.white-btn-fm{
    background-color: #5271ff;
    width: 100px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    color: #ffffff;
}

.card-db-bgd{
 background-color: #ffffff;
}
   .wht-txt-btm{
       color: #ffffff;
       font-weight: bold;
       font-size: 18px;
   }
   .wht-ligth-btm{
    color: #ffffff;
    font-weight: 400;
    font-size: 18px;
}

.btm-msg-bgd{
    background-color: #5271ff;
}

.prf-nm-modal{
    color: #5271ff;
    font-size: 16px;
}

.prf-flex-box{
    display: flex;
}

.modal-rev-field{
    height: 50px;
}

.submit-btn-md{
width: 80px;
height: 30px;
background-color: #5271ff;
color: #ffffff;
text-align: center;
}

.md-btn-right{
    display: flex;
    justify-content: flex-end;
}



              