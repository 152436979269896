.fm-img-circle{
    width: 80px;
    height: 80px;
}
.monument-img{
    width: 100%;
    height:50vh;
}

.flex-fm-center{
    display: flex;
    justify-content: center;
}

.fm-name-ctr{
    color: #559ee2;
    font-size: 20px;
    text-align: center;
}

.fm-color-txt{
    color: #6883ff;
    font-size: 48px;
    font-weight: 300;
}

.fm-color-bold{
    color: #6883ff;
    font-size: 52px;
   
   }

   .main-wrapper-container{
       margin: 0 auto;
       width: 90%;
   }

   .btm-fm-border{
    border-bottom: 3px solid #559ee2;
    width: 304px;
    margin-bottom: 44px;
    margin-top: 44px;
   }

   .era-fm-txt{
     font-size: 20px;
   }

   .rd-link{
    color: #fff !important;
    font-size: 18px;
    font-weight: 500;
   }


   .rd-btn-sect1 {
    background-color: #6883ff;
    width: 232px;
    height: 57px;
    border-radius: 20px;
    text-align: center;
    padding-top: 14px;
}

.sect2-head{
    text-align: center;
    color: #6883ff;
    font-weight: 600;
    font-size: 32px;
}


.rt-btn-curator{
    background-color: #6883ff;
    width: 232px;
    height: 57px;
    border-radius: 20px;
    text-align: center;
    padding-top: 14px;
}

.flex-fm-end{
    display: flex;
    justify-content:flex-end;
}
.fm-color-txt.shft-right{
text-align: right;
}

.fm-color-bold.shft-right{
    text-align: right;
}

.border-right{
display: flex;
justify-content: flex-end;
}

.rt-desc-curator{
    text-align: right;
}

.faithminus-section2{
    background-color:#f6f6f6 ;
}


.sect2-fm-head{
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
}

.pltfrm1{
    width:100px;
    height:120px

}


.plfrm-border{
  display:flex;
  justify-content: space-evenly;
}


.faithminus-section4{
    margin-top: 20px;
    margin-bottom: 20px;
}
































